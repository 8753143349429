import { Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { IMakeAChangeDataset } from '../../types/make-a-change-dataset-interface';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChoiceBankComponent } from '@app/shared/components/choice-bank/choice-bank.component';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { ATTEMPT_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';

@Component({
  selector: 'app-mac-puzzle-type-2',
  standalone: true,
  imports: [LetterPanelComponent, CommonModule, ChoiceBankComponent, NgOptimizedImage],
  templateUrl: './puzzle-type-2.component.html',
  styleUrl: './puzzle-type-2.component.scss',
})
export class MACPuzzleType2Component extends PuzzleTypeBaseComponent {
  @Input() currentMacDataset: IMakeAChangeDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() newGroup: boolean;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() subPartComplete = new EventEmitter();
  @ViewChild('choiceBank') choiceBankComponent: ChoiceBankComponent;



  protected showChain: boolean = false;
  protected formatChain: any[] = [];
  protected resultLetters: any[] = [];
  protected currentLevel = this.appStateService.level;
  protected rightButtonImage = `/assets/arrow-right-dark-${this.currentLevel}.svg`;

  protected letterPanelsActiveWord: ILetterPanel[] = [];
  protected letterPanelsChoices: ILetterPanel[] = [];
  
  protected prompt: string[] = [];
  protected choices: string[] = [];
  protected answers: string[] = [];
  protected puzzleTypes: number[] = [];

  private targetPanel: ILetterPanel;

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.initActiveWord();
    

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: '',
    })

    const tempActive =  JSON.parse(JSON.stringify(this.letterPanelsActiveWord))
    this.resultLetters.push(...tempActive);
    this.resultLetters.forEach((lp) => {
      lp.success = false;
      lp.active = false;
    });

    this.formatChain = this.splitChainResult(this.resultLetters);

    if (!this.currentMacDataset?.expressions) {
      setTimeout(() =>{
        this.showChain = true;
      }, DELAY.S2)
      return;
    } else {
      this.showChain = false;
      this.initChoicePanels();
    }

    if (this.newGroup) {
      this.startPuzzleMetrics();
      this.answers = [];
      this.prompt = [];
    }

    this.resetTries();
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentMacDataset?.maxTries)

    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => lp.isCorrectAnswer)[0];
    this.validatingPuzzle = false;

    this.soundService.playExpression(this.getExpression(1), () => {
      AnimationHelpers.animate('active-word', ANIMATIONS.Pulse);
      if (this.getExpression(2)) {
        this.soundService.playExpression(this.getExpression(2), () => {
          AnimationHelpers.animate('choice-bank', ANIMATIONS.Pulse);
        });
      }
    });

    if (!this.showChain) {
      this.appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.PUZZLE_CLUE,
        puzzleClueExpression: this.getExpression(1) ?? '',
      });
    }

    this.letterPanelsChoices.forEach(el => {
      if(el.isCorrectAnswer){
        this.answers.push(el.content)
      }
      return;
    });
    this.prompt.push(this.getExpression(1) ?? '')
    this.choices = this.letterPanelsChoices.map(el => el.content); // do we even want to display choices?
    this.puzzleTypes = this.currentMacDataset ? [this.currentMacDataset.puzzleType] : [];
    this.addPuzzleMeta(this.prompt,this.choices, this.answers, this.puzzleTypes);
  }


  initActiveWord() {
    if (this.currentMacDataset?.word) {
      AppHelpers.buildTargetWordPanels(this.currentMacDataset.word, this.letterPanelsActiveWord);
    }
    this.letterPanelsActiveWord.forEach((lp) => {
      lp.hidden = false;
    });
    AnimationHelpers.animate('active-word', ANIMATIONS.Pulse);
  }

  initChoicePanels() {
    if (this.currentMacDataset?.choices) {
      AppHelpers.buildChoicePanels(this.currentMacDataset.choices, this.letterPanelsChoices);
    }
  }

  onLetterChoiceSelected(selectedPanel: ILetterPanel) {
    if (this.validatingPuzzle) {
      return;
    }
    const selectedChoice = this.choiceBankComponent.letterPanels.find(panel => panel.letterPanel === selectedPanel);

    this.soundService.playSound(SOUND_NAMES.Click);

    this.validatingPuzzle = true;
    selectedPanel.active = true;

    if (selectedPanel.isCorrectAnswer) {
      selectedChoice?.displayMark('correct');
      this.addUserAttemptDetails({
        content: selectedPanel.content,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.PASS,
        subPartIdx: this.prompt.length - 1
      });

      selectedPanel.success = true;

      if (this.getExpression(1)?.includes('add') || this.getExpression(1)?.includes('Add')) {
        const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
        this.soundService.playSound(SOUND_NAMES.Correct, () => {
          selectedPanel.success = false;
          selectedPanel.active = false;
          selectedChoice?.displayMark('');
          this.soundService.playExpression(this.getExpression(3), () => {
            this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
            this.subPartComplete.emit(false);
            this.emitCall.emit();
          });
        });
      } else {
        this.animatePanel(selectedPanel, () => {
          const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
          this.soundService.playSound(SOUND_NAMES.Correct, () => {
            selectedPanel.success = false;
            selectedPanel.active = false;
            selectedChoice?.displayMark('');
            this.soundService.playExpression(this.getExpression(3), () => {
              this.burst.animate({soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
              this.subPartComplete.emit(false);
              this.emitCall.emit();
            });
          });
        });
      }
    } else {
      selectedPanel.error = true;

      this.addUserAttemptDetails({
        content: selectedPanel.content,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.FAIL,
        subPartIdx: this.prompt.length - 1
      });



      AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);

      if (this.hasTriesExceeded()) {
        this.soundService.playSound(SOUND_NAMES.Incorrect);
        this.addMistake();
        selectedChoice?.displayMark('incorrect');

        if (!this._selfCorrected) {
          this.subpartSelfCorrected(true);
        }

        const correctPanel = this.letterPanelsChoices.filter((lp) => lp.isCorrectAnswer)[0];

        this.soundService.playExpression(this.getExpression(5), () => {
          selectedPanel.error = false;
          selectedPanel.active = false;
          selectedChoice?.displayMark('');
          correctPanel.success = true;
          if (this.getExpression(1)?.includes('add') || this.getExpression(1)?.includes('Add')) {
            this.soundService.playSound(SOUND_NAMES.Correct, () => {
              selectedPanel.success = false;
              selectedPanel.active = false;
              this.soundService.playExpression(this.getExpression(3), () => {
                const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
                this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
                this.subPartComplete.emit(false);
                this.emitCall.emit();
              });
            });
          } else {
            this.animatePanel(correctPanel, () => {
              correctPanel.success = false;
              selectedPanel.success = false;
              selectedPanel.active = false;
              this.subPartComplete.emit(false);
              this.emitCall.emit();
            });
          }
        });
      } else {
        this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
          this.soundService.playExpression(this.getExpression(4), () => {
            selectedPanel.error = false;
            selectedPanel.active = false;
            this.validatingPuzzle = false;
          });
        });
      }
    }
  }

  private animatePanel(panel: ILetterPanel, callback?: () => void | undefined) {
    if (!panel) {
      if (callback) callback();
      return;
    }

    this.anim.animateTo(panel.id, this.targetPanel?.id, () => {
      if (this.targetPanel) {
        this.targetPanel.complete = true;
        this.targetPanel.content = panel.content;
        AnimationHelpers.animate(this.targetPanel?.id, ANIMATIONS.Bounce);
      }
        if (callback) callback();
    });
  }

  isLastBeforeWord(index: number, array?) {
    if (array) {
      if (index === array.length - 1) return false;
      return array[index + 1].index === 0;
    } else {
      if (index === this.resultLetters.length - 1) return false;
      return this.resultLetters[index + 1].index === 0;
    }
  }

  private splitChainResult(array) {
    const segmentMax = 6;
    const result: any[] = [];

    let segment: any[] = [];
    let currentWord: any[] = [];

    array.forEach((lp, i) => {
      currentWord.push(lp);
      if (this.isLastBeforeWord(i) && currentWord.length > 0) {
        segment.push(...currentWord);
        currentWord = [];
        if (segment.length <= segmentMax) {
          result.push(segment);
          segment = [];
        }
      }

      if (currentWord.length <= 6 && lp.index === 0) {
        segment.push(...currentWord);
        currentWord = [];

        if (segment.length === segmentMax) {
          result.push(segment);
          segment = [];
        }
      }
    });

    if (currentWord.length > 0) {
      segment.push(...currentWord);
    }

    if (segment.length > 0) {
      result.push(segment);
    }

    return result;
  }

  resumePuzzle() {
    setTimeout(() => {
      this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
      setTimeout(() => {
        this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
      }, DELAY.S1)
    }, DELAY.S1);
    this.formatChain = [];
    this.resultLetters = [];
  }
}
