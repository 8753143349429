import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, inject } from '@angular/core';
import { SoundService } from '@app/shared/services/sound.service';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';
import { ITrickWordsDataset } from '../../types/tw-dataset-interface';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { DELAY } from '@app/shared/constants/delay-enums';
import { NotSoTrickyActivityComponent } from '../../not-so-tricky-activity.component';
import { ButtonModule } from 'primeng/button';
import { COMMA_SEPARATOR, EXCLAMATION_MARK, PERIOD, QUESTION_MARK } from '@app/shared/constants/activity-constants';
import { ATTEMPT_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { AnimationBurst } from '@app/shared/services/animation-burst.service';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';

@Component({
  selector: 'app-tw-sentence',
  templateUrl: './tw-sentence.component.html',
  styleUrls: ['./tw-sentence.component.scss'],
  imports: [CommonModule, ButtonModule],
  standalone: true,
})
export class TWSentenceComponent extends PuzzleTypeBaseComponent implements OnChanges {
  @Input() trickWordDataset?: ITrickWordsDataset;

  @Input() parent?: NotSoTrickyActivityComponent;

  @Output() completedPuzzle = new EventEmitter();
  protected currentChoiceIdx = -1;
  protected currentCorrectIdx = -1;
  protected currentErrorIdx = -1;
  protected choices: string[] = [];
  protected puzzleTypes: number[] = [];
  protected trickWord = '';
  protected isPuzzleSolved = false;
  protected isStaged = false;
  protected wordPlaceHolder = '_ ';
  protected sentenceFragments: string[] = [];
  protected wordDefaultText = '';
  protected wordDiv = 'wordDiv';

  private _validatingPuzzle = false;

  private readonly _burst = inject(AnimationBurst);

  @HostListener('document:keyup', ['$event']) handleKeyEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case KEY_EVENTS.ArrowRight:
        this.currentChoiceIdx = this.currentChoiceIdx + 1 < this.choices.length ? this.currentChoiceIdx + 1 : 0;
        break;
      case KEY_EVENTS.ArrowLeft:
        this.currentChoiceIdx = this.currentChoiceIdx - 1 >= 0 ? this.currentChoiceIdx - 1 : this.choices.length - 1;
        break;
      case KEY_EVENTS.SpaceBar:
      case KEY_EVENTS.Enter:
        if (this.currentChoiceIdx >= 0) {
          this.onChoiceClicked(this.currentChoiceIdx);
        }
        break;
      default:
        break;
    }
  }
  constructor(private readonly _soundService: SoundService) {
    super();
  }

  ngOnChanges(): void {
    this.initPuzzle();
  }

  initPuzzle() {
    if (this.trickWordDataset) {
      // new puzzle
      this.startPuzzleMetrics()
      this.isPuzzleSolved = false;
      this.currentChoiceIdx = -1;
      this.currentCorrectIdx = -1;
      this.currentErrorIdx = -1;
      this.setMaxTries(this.trickWordDataset?.maxTries)
      this.resetTries()
      // must seperate any commas with a space before it for proper segments.
      const sentence = this.trickWordDataset?.sentence.replace(COMMA_SEPARATOR, ' ,');
      let frags = sentence.split(' ');
      this.trickWord = this.trickWordDataset?.word.trimAll();

      // trim frags
      frags = frags.map((f) => f.trimStart());

      const idx = frags.findIndex(
        (f) =>
          f
            .toLowerCase()
            .replaceAll(PERIOD, '')
            .replaceAll(QUESTION_MARK, '')
            .replaceAll(EXCLAMATION_MARK, '')
            .replaceAll(COMMA_SEPARATOR, '') === this.trickWord.toLowerCase().replaceAll(PERIOD, '').replaceAll(EXCLAMATION_MARK, '')
      );

      this.sentenceFragments = [];
      if (idx >= 0) {
        frags[idx] = `*${frags[idx]}`;
        this.sentenceFragments = frags.join(' ').split(frags[idx]);

        if (this.trickWordDataset?.sentence.includes(QUESTION_MARK) && !this.sentenceFragments[1].includes(QUESTION_MARK)) {
          this.sentenceFragments[1] += QUESTION_MARK;
        } else if (this.trickWordDataset?.sentence.includes(EXCLAMATION_MARK) && !this.sentenceFragments[1].includes(EXCLAMATION_MARK)) {
          this.sentenceFragments[1] += EXCLAMATION_MARK;
        } else if (this.trickWordDataset?.sentence.includes(PERIOD) && !this.sentenceFragments[1].includes(PERIOD)) {
          this.sentenceFragments[1] += PERIOD;
        }

        // cleanup, remove space before these chars
        this.sentenceFragments[0] = this.sentenceFragments[0].replaceAll(` ${COMMA_SEPARATOR}`, COMMA_SEPARATOR);
        this.sentenceFragments[1] = this.sentenceFragments[1].replaceAll(` ${COMMA_SEPARATOR}`, COMMA_SEPARATOR);
        this.sentenceFragments[0] = this.sentenceFragments[0].replaceAll(` ${PERIOD}`, PERIOD);
        this.sentenceFragments[1] = this.sentenceFragments[1].replaceAll(` ${PERIOD}`, PERIOD);
        this.sentenceFragments[0] = this.sentenceFragments[0].replaceAll(` ${QUESTION_MARK}`, QUESTION_MARK);
        this.sentenceFragments[1] = this.sentenceFragments[1].replaceAll(` ${QUESTION_MARK}`, QUESTION_MARK);
      }
      AnimationHelpers.animate('sentence-host', ANIMATIONS.BackInDown);
      AnimationHelpers.animate('tw-choices', ANIMATIONS.FadeIn);
      this.choices = this.trickWordDataset.choices.replaceAll(' ', '').split(',');
      const prompt = [this.trickWordDataset?.sentence]
      this.puzzleTypes = this.trickWordDataset ? [this.trickWordDataset.puzzleType] : [];
      this.addPuzzleMeta(prompt,this.choices, [this.trickWord], this.puzzleTypes);
    }
  }

  onChoiceClicked(id: number) {
    if (this._validatingPuzzle) {
      return;
    }

    this._validatingPuzzle = true;
    this.currentChoiceIdx = id;
    const choice = this.choices[this.currentChoiceIdx];

    this._soundService.playExpression(choice, () => {
      this.validatePuzzle();
    });
  }

  onFocusChange(idx: number) {
    if (!this._validatingPuzzle && idx > -1) {
      this.currentChoiceIdx = idx;
    }
  }

  validatePuzzle() {
    // is puzzle solved ?
    const choice = this.choices[this.currentChoiceIdx];
    const solved = this.trickWord.toLowerCase().trimAll() === choice.toLowerCase().trimAll();

    if (solved) {

      this.addUserAttemptDetails({
        content: choice,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.PASS,
        subPartIdx: 0
      });

      this.currentCorrectIdx = this.currentChoiceIdx;
      AnimationHelpers.animate(this.currentChoiceIdx.toString(), ANIMATIONS.Bounce);

      this._soundService.playSound(SOUND_NAMES.ActivityComplete, () => {
        this.anim.animateTo(this.currentCorrectIdx.toString(), this.wordDiv, () => {
          this.currentChoiceIdx = -1;
          this.wordDefaultText = this.buildCorrectWord(choice);
          this.isStaged = true;
          AnimationHelpers.animate(this.wordDiv, ANIMATIONS.Bounce);

          this._soundService.playExpression(this.parent?.getExpression(1), () => {
            this._burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: `${this.currentCorrectIdx}` }, BURST_TYPE.Round, true);

            setTimeout(() => {
              this._soundService.playExpression(this.trickWordDataset?.sentence, () => {
                setTimeout(() => {
                  this.isStaged = false;
                  this.endPuzzleMetrics(PUZZLE_STATUS.PASS);
                  this.resetTries();
                  this.completedPuzzle.emit({ passed: !this._selfCorrected });
                  this.isPuzzleSolved = true;
                  this._validatingPuzzle = false;
                }, DELAY.S2);
              });
            }, DELAY.S1)
          });
        });
      });
    } else {
      this.currentErrorIdx = this.currentChoiceIdx;

      this.addUserAttemptDetails({
        content: choice,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.FAIL,
        subPartIdx: 0
      });

      AnimationHelpers.animate(this.currentChoiceIdx.toString(), ANIMATIONS.ShakeX);
      this.currentCorrectIdx = -1;

      if (this.hasTriesExceeded()) {
        this._soundService.playSound(SOUND_NAMES.Incorrect);

        this.addMistake();

        this._soundService.playExpression(this.parent?.getExpression(3), () => {
          this.currentChoiceIdx = this.choices.findIndex((c) => c.toLowerCase().trimAll() === this.trickWord.toLowerCase().trimAll());
          this.currentErrorIdx = -1;
          this.currentCorrectIdx = this.currentChoiceIdx;
          this.wordDefaultText = this.buildCorrectWord(this.choices[this.currentChoiceIdx]);
          this.isStaged = true;

          AnimationHelpers.animate(this.currentChoiceIdx.toString(), ANIMATIONS.Bounce);
          AnimationHelpers.animate(this.wordDiv, ANIMATIONS.Bounce);
          setTimeout(() => {
            this._soundService.playSound(SOUND_NAMES.Correct, () => {
              this._soundService.playExpression(this.trickWordDataset?.sentence, () => {
                setTimeout(() => {
                  this.isStaged = false;
                  this.resetTries();
                  this.endPuzzleMetrics(PUZZLE_STATUS.FAIL);
                  this.completedPuzzle.emit({ passed: this._selfCorrected });
                  this.isPuzzleSolved = true;
                  this._validatingPuzzle = false;
                }, DELAY.S1 + DELAY.SMALL);
              });
            });
          }, DELAY.SMEDIUM)
        });
      } else {
        this._soundService.playSound(SOUND_NAMES.Incorrect, () => {
          this._soundService.playExpression(this.parent?.getExpression(2), () => {
            this.isStaged = false;
            this.currentErrorIdx = -1;
            this.currentCorrectIdx = -1;
            this.currentChoiceIdx = -1;
            this._validatingPuzzle = false;
          });
        });
      }
    }
  }

  buildCorrectWord(text: string) {
    // Upper case first letter if correct word is 1st word in sentence
    let finalText = text;
    if (this.sentenceFragments.length > 0) {
      if (this.sentenceFragments[0].length === 0) {
        finalText = text[0].toUpperCase() + text.slice(1);
      }
    }
    return finalText.trimAll();
  }
}
