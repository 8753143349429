import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoundService } from '@app/shared/services/sound.service';
import { LetterPanelComponent } from '../letter-panel/letter-panel.component';
import { ILetterPanel } from '../letter-panel/types/letter-panel-interface';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { DELAY } from '@app/shared/constants/delay-enums';
import { AppStateService } from '@app/shared/services/app-state.service';

@Component({
  selector: 'app-choice-bank',
  standalone: true,
  templateUrl: './choice-bank.component.html',
  styleUrl: './choice-bank.component.scss',
  imports: [CommonModule, LetterPanelComponent],
})
export class ChoiceBankComponent implements OnInit, OnChanges{
  @Input() choices: ILetterPanel[];
  @Input() validatingPuzzle: boolean;
  @Output() chosenPanel = new EventEmitter<any>();


  @ViewChildren(LetterPanelComponent) letterPanels!: QueryList<LetterPanelComponent>;

  private appStateService = inject(AppStateService);

  protected containsGroup: boolean = false;
  protected currentLevel: number | undefined = this.appStateService.level;




  constructor(private readonly _soundService: SoundService) {}

  ngOnInit(){
    setTimeout(() =>{
      this.applyGroup();
    }, DELAY.ZERO)
  }

  ngOnChanges(){
    setTimeout(() =>{
      this.applyGroup();
    }, DELAY.ZERO)
  }

  onLetterChoiceClicked(selectedPanel: ILetterPanel) {
    if(this.validatingPuzzle){
      return;
    }
    this._soundService.playSound(SOUND_NAMES.Click);

    this.chosenPanel.emit(selectedPanel);
  }

  private applyGroup(){
    const underscorePanel = this.choices.find(lp => lp.content === '_');

    if(underscorePanel){
      this.containsGroup = true;
      const underscoreIndex = this.choices.indexOf(underscorePanel);
      const beforePanel = this.choices[underscoreIndex - 1];
      const afterPanel = this.choices[underscoreIndex + 1];

      if(beforePanel && afterPanel){
        underscorePanel.isGrouped = true;
        beforePanel.isGrouped = true;
        afterPanel.isGrouped = true;

        const compLeft = this.letterPanels.toArray().find((lc) => lc.letterPanel === beforePanel);
        const compMiddle = this.letterPanels.toArray().find((lc) => lc.letterPanel === underscorePanel);
        const compRight = this.letterPanels.toArray().find((lc) => lc.letterPanel === afterPanel);

        if (compLeft && compMiddle && compRight) {
          compLeft.showLeftBorder = true;
          compLeft.showTopBorder = true;
          compLeft.showBottomBorder = true;
          compMiddle.showTopBorder = true;
          compMiddle.showBottomBorder = true;
          compRight.showRightBorder = true;
          compRight.showTopBorder = true;
          compRight.showBottomBorder = true;
        }
      }
    }
  }
}
