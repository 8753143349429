import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEchoFindWordsDataset } from '../../types/echo-find-words-dataset-interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { ATTEMPT_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { ChoiceBankComponent } from '@app/shared/components/choice-bank/choice-bank.component';
import { CommonModule } from '@angular/common';
import { DELAY } from '@app/shared/constants/delay-enums';

import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';

@Component({
  selector: 'app-bwe-puzzle-type-3',
  standalone: true,
  imports: [LetterPanelComponent, ChoiceBankComponent, CommonModule],
  templateUrl: './puzzle-type-3.component.html',
  styleUrl: './puzzle-type-3.component.scss',
})
export class BWEPuzzleType3Component extends PuzzleTypeBaseComponent {
  @Input() currentBuildEchoDataset: IEchoFindWordsDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() positionCommands: Map<number, string>;
  @Input() currentActivity: any;
  @Input() skipIntro: boolean = false;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() subPartComplete = new EventEmitter();

  protected letterPanelsActiveWord: ILetterPanel[] = [];
  protected letterPanelsBaseWord: ILetterPanel[] = [];
  protected letterPanelsChoices: ILetterPanel[] = [];

  protected baseWordMode: boolean = false;
  protected droppedSuffixMode: boolean = false;

  protected prompt: string[] = [];
  protected choices: string[] = [];
  protected answers: string[] = [];
  protected puzzleTypes: number[] = [];


  private targetPanel: ILetterPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.startPuzzleMetrics();
    this.setExpressions(this.expressions);
    this.initActiveWord();
    this.initChoicePanels();
    this.setMaxTries(this.currentBuildEchoDataset?.maxTries)
    this.answers = [];

    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];
    this.validatingPuzzle = false;
    this.droppedSuffixMode = false;
    this.baseWordMode = false;

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: this.getExpression(1) ?? '',
    });

    this.letterPanelsChoices.forEach(el => {
      if(el.isCorrectAnswer){
        this.answers.push(el.content)
      }
      return;
    });
    
    this.prompt = [this.currentBuildEchoDataset?.word ?? ''];
   this.choices = this.letterPanelsChoices.map(el => el.content);
   this.puzzleTypes = this.currentBuildEchoDataset ? [this.currentBuildEchoDataset.puzzleType] : [];
   this.addPuzzleMeta(this.prompt,this.choices, this.answers, this.puzzleTypes);
  }

  initActiveWord() {
    if (this.droppedSuffixMode) {
      this.letterPanelsActiveWord = [];
      this.letterPanelsBaseWord = [];
    }
    if (this.currentBuildEchoDataset) {
      AppHelpers.buildTargetWordPanels(this.currentBuildEchoDataset.word, this.letterPanelsActiveWord);
      AppHelpers.buildTargetWordPanels(this.currentBuildEchoDataset.baseWord, this.letterPanelsBaseWord);
    }
  }

  initChoicePanels() {
    if (this.currentBuildEchoDataset) {
      AppHelpers.buildChoicePanels(this.currentBuildEchoDataset.choices, this.letterPanelsChoices);
    }
  }

  onLetterChoiceSelected(selectedPanel: ILetterPanel) {
    if (this.validatingPuzzle) {
      return;
    }

    this.validatingPuzzle = true;
    selectedPanel.active = true;


    if ((this.baseWordMode && !this.droppedSuffixMode)) {
      selectedPanel.error = true;

      // failing to drop suffix
      this.addUserAttemptDetails({
        content: selectedPanel.content,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.FAIL,
        subPartIdx: 0
      });

      AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);
      if (this.hasTriesExceeded()) {
        this.addMistake();

        if (!this._selfCorrected) {
          this.subpartSelfCorrected(true);
        }
        let correctPanel = this.letterPanelsChoices.filter(
          (lp) => lp.content === this.letterPanelsBaseWord[this.targetPanel.index].content
        )[0];

        correctPanel = this.letterPanelsActiveWord[this.targetPanel.index];
        this.dropSuffix(correctPanel, true);
        return;
      } else {
        this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
          this.soundService.playExpression(this.getPositionCommand(2), () => {
            selectedPanel.error = false;
            selectedPanel.active = false;
            this.validatingPuzzle = false;
          });
        });
      }
      return;
    }

    if (
      selectedPanel.content === this.letterPanelsBaseWord[this.letterPanelsBaseWord.length - 1].content &&
      (this.targetPanel.index === this.currentBuildEchoDataset?.position) &&
      !this.droppedSuffixMode
    ) {
      selectedPanel.success = true;
      // add success for adding e or y
      this.addUserAttemptDetails({
        content: selectedPanel.content,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.PASS,
        subPartIdx: 0
      });

      this.baseWordApproach(selectedPanel);
      return;
    }

    if (selectedPanel.isCorrectAnswer &&
      selectedPanel.content === this.targetPanel.content &&
      selectedPanel.gluedSound === this.targetPanel.gluedSound &&
      selectedPanel.suffix === this.targetPanel.suffix &&
      selectedPanel.vowel === this.targetPanel.vowel) {

      selectedPanel.success = true;

      this.addUserAttemptDetails({
        content: selectedPanel.content,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.PASS,
        subPartIdx: 0
      });

      this.animatePanel(selectedPanel, () => {
        this.soundService.playSound(SOUND_NAMES.Correct, () => {
          selectedPanel.success = false;
          selectedPanel.active = false;
        });

        if (!this.getNextTarget()) {
          this.soundService.playExpression(this.getExpression(2), () => {
            const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
            this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
            setTimeout(() => {
              this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
              setTimeout(() => {
                this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
              }, DELAY.S2)
            }, DELAY.S2);
          });
        } else {
          this.subPartComplete.emit(false);
          this.validatingPuzzle = false;
        }
      });
    } else {
      selectedPanel.error = true;

      this.addUserAttemptDetails({
        content: selectedPanel.content,
        context: ATTEMPT_CONTEXT.Word,
        result: PUZZLE_STATUS.FAIL,
        subPartIdx: 0
      });

      AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);

      if (this.hasTriesExceeded()) {
        this.addMistake();

        if (!this._selfCorrected) {
          this.subpartSelfCorrected(true);
        }

        let correctPanel = this.letterPanelsChoices.filter((lp) => lp.isCorrectAnswer && lp.content === this.targetPanel?.content)[0];

        if (this._selfCorrected && this.targetPanel.index === this.currentBuildEchoDataset?.position && !this.droppedSuffixMode) {
          correctPanel = this.letterPanelsChoices.filter(
            (lp) => lp.content === this.letterPanelsBaseWord[this.targetPanel.index].content
          )[0];
          this.baseWordApproach(correctPanel, true);
          setTimeout(() => {
            selectedPanel.error = false;
            selectedPanel.active = false;
          }, DELAY.SMALL);
          return;
        }

        this.soundService.playSound(SOUND_NAMES.Incorrect);
        this.soundService.playExpression(this.getExpression(4), () => {
          selectedPanel.error = false;
          selectedPanel.active = false;

          if (correctPanel) {
            this.animatePanel(correctPanel, () => {
              if (!this.getNextTarget()) {
                this.validatingPuzzle = true;
                this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
              } else {
                this.subPartComplete.emit(false);
                this.validatingPuzzle = false;
              }
            });
          }
        });
      } else {
        if (!this.baseWordMode && !this.droppedSuffixMode && this.targetPanel.index === this.currentBuildEchoDataset?.position) {
          this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
            this.soundService.playExpression(this.getPositionCommand(2), () => {
              selectedPanel.error = false;
              selectedPanel.active = false;
              this.validatingPuzzle = false;
            });
          });
          return;
        }

        this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
          this.soundService.playExpression(this.getExpression(3), () => {
            selectedPanel.error = false;
            selectedPanel.active = false;
            this.validatingPuzzle = false;
          });
        });
      }
    }
  }

  private animatePanel(panel: ILetterPanel, callback?: () => void | undefined) {
    if (!panel) {
      if (callback) callback();
      return;
    }

    this.anim.animateTo(panel.id, this.targetPanel?.id, () => {
      if (this.targetPanel) {
        this.targetPanel.complete = true;
      }
      if (callback) callback();
    });
  }

  getNextTarget() {
    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];
    if (!this.targetPanel) {
      this.resetTries();
      return false;
    }
    this.resetTries();
    return true;
  }

  baseWordApproach(selectedPanel: ILetterPanel, autosolve = false) {
    this.animatePanel(selectedPanel, () => {
      this.letterPanelsActiveWord = this.letterPanelsBaseWord;
      this.letterPanelsActiveWord.forEach((letterPanel) => {
        letterPanel.complete = true;
      });
      this.soundService.playSound(autosolve ? SOUND_NAMES.Incorrect : SOUND_NAMES.Correct, () => {
        selectedPanel.error = false;
        selectedPanel.success = false;
        selectedPanel.active = false;
        this.soundService.playExpression(autosolve ? this.getPositionCommand(3) : this.getPositionCommand(1), () => {
          this.baseWordMode = true;
          this.validatingPuzzle = false;
          this.resetTries();
        });
      });
    });
  }

  dropSuffix(selectedPanel: ILetterPanel, autosolve = false) {
    if (selectedPanel.index !== this.currentBuildEchoDataset?.position && !this.baseWordMode && !autosolve) {
      return;
    }

    selectedPanel.hidden = true;
    this.baseWordMode = false;
    this.droppedSuffixMode = true;
    this.initActiveWord();
    this.letterPanelsActiveWord.forEach((letterPanel, i) => {
      if (i < this.targetPanel.index) {
        letterPanel.complete = true;
      } else {
        letterPanel.complete = false;
      }
    });
    this.getNextTarget();
    this.validatingPuzzle = false;

    if (autosolve) {
      const correctPanel = this.letterPanelsChoices.filter((lp) => lp.isCorrectAnswer && lp.content === this.targetPanel?.content)[0];
      this.soundService.playExpression(this.getPositionCommand(3), () => {
        this.animatePanel(correctPanel, () => {
          if (!this.getNextTarget()) {
            this.validatingPuzzle = true;
            setTimeout(() => {
              this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
              setTimeout(() => {
                this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
              }, DELAY.S2)
            }, DELAY.S2);
          } else {
            this.subPartComplete.emit(false);

            this.validatingPuzzle = false;
          }
        });
      });
    }
  }

  getPositionCommand(key: number) {
    return this.positionCommands.get(key);
  }
}
