import { AfterViewInit, Component, DestroyRef, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, fromEvent, startWith, tap, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceOrientation, getDeviceOrientation } from '@app/shared/helpers/device-orientation';
import { ConfirmationService } from 'primeng/api';
import { AppStateService } from '@app/shared/services/app-state.service';
import { Params, Router } from '@angular/router';
import { IActivityRegistry } from '@app/shared/types/activity-registry.interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { SoundService } from '@app/shared/services/sound.service';
import { environment } from '@env/environment';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';

@Component({
  selector: 'app-activity-header',
  templateUrl: './activity-header.component.html',
  styleUrls: ['./activity-header.component.scss'],
})
export class ActivityHeaderComponent implements OnInit, AfterViewInit {
  @Input() activityCompleted: boolean;
  @Input() params: Params;
  @Input() activity: IActivityRegistry;
  @Input() keyboardInstructionsExpression = '';
  @Input() isDarkBackground: boolean;
  @Input() activityStarted: boolean;

  @ViewChild('fullScreenBtn', { static: false }) fullScreenBtn: ElementRef;

  protected showExitDialog = false;
  protected keyboardVisibility = false;
  protected keyboardButtonImage = '';
  protected fullScreenButtonImage = '';
  protected exitFullScreenButtonImage = '';
  protected exitButtonImage = '';
  protected soundButtonImage = '';
  protected titleImage = '';
  protected activityCompletionPageShown = false;

  private readonly  _destroyRef = inject(DestroyRef);
  private readonly  _dialogConfirmationService = inject(ConfirmationService);
  private readonly  _appStateService = inject(AppStateService);
  private readonly  _soundService = inject(SoundService);

  protected appEvent$ = this._appStateService.appEvent$;

  protected isFullScreen = false;
  protected unitDisplay = '';

  private _playingAudio = false;

  isTextToSpeechPlaying$: BehaviorSubject<boolean>;

  constructor(private readonly _router: Router) {}

  ngOnInit() {
    this.isTextToSpeechPlaying$ = this._soundService.soundIsPlaying$;

    const mode = this.isDarkBackground ? 'white' : 'black';

    this.titleImage = `/assets/fundations-text-${mode}.svg`;
    this.keyboardButtonImage = `/assets/keyboard-${mode}.svg`;
    this.fullScreenButtonImage = `/assets/fullscreen-button-${mode}.svg`;
    this.exitFullScreenButtonImage = `/assets/exit-fullscreen-button-${mode}.svg`;
    this.exitButtonImage = `/assets/exit-button-${mode}.svg`;
    this.soundButtonImage = `/assets/sound-button-${this._appStateService.level}.svg`;

    const unit = this.params['u'] as string;
    if (unit.includes('.5')) {
      this.unitDisplay = `Bonus Unit`;
    } else {
      this.unitDisplay = ` Unit ${unit}`;
    }

    this.appEvent$
      .pipe(
        filter((event) => event.area === APP_EVENT_AREAS.ACTIVITY_COMPLETE),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((event) => {
        this.activityCompletionPageShown = event.showActivityComplete ?? false;
      });
  }

  ngAfterViewInit() {
    this.registerDeviceOrientationEvent();
  }

  onKeyboardButtonClick() {
    this.keyboardVisibility = !this.keyboardVisibility;
    if (this.keyboardVisibility) {
      setTimeout(() => {
        this.onPlayKeyboardInstructions();
      }, DELAY.SMEDIUM);
    }
  }

  onPlayKeyboardInstructions() {
    if (this._playingAudio) {
      return;
    }
    this._playingAudio = true;

    this._soundService.playExpression(
      this.keyboardInstructionsExpression,
      () => {
        this._playingAudio = false;
      },
      true
    );
  }

  onCloseClick() {
    this.showExitDialog = true;
  }

  closeExitDialog(confirm: boolean) {
    this.showExitDialog = false;
    if (confirm) {
      this._soundService.stopExpression();
      setTimeout(() => {
        this._router.navigate(['/dashboard']);
      }, DELAY.S2);
    }
  }

  closeKeyboardInstructions(){
    if(this._soundService.soundIsPlaying$.value){
      this._soundService.stopExpression();
      this._playingAudio = false;
      this._appStateService.appEvent$.next({ area: APP_EVENT_AREAS.Sound, disableUserInteraction: false });
    }
  }

  protected playTextToSpeech(): void {
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/B671C5070C4FF1E67354E47771EA5C3FBF63575AD3BF1A8D93C8ABD53CFCE3D1.mp3`,
      1,
      false
    );
  }

  protected enterFullScreenMode(): void {
    this.enterFullScreen();
  }
  
  protected exitFullScreenMode(): void {
    this.exitFullScreen();
  }
  

  private enterFullScreen(): void {
    const docEl = document.body as any;
    if (!docEl) return;

    if (docEl['requestFullscreen']) docEl['requestFullscreen']();
    else if (docEl['webkitRequestFullScreen']) docEl['webkitRequestFullScreen'](); // safari
    else if (docEl['webkitEnterFullScreen']) docEl['webkitEnterFullScreen'](); // safari mobile (older iOS)
    else if (docEl['mozRequestFullScreen']) docEl['mozRequestFullScreen'](); // firefox
    else if (docEl['msRequestFullscreen']) docEl['msRequestFullscreen'](); // IE
    this.isFullScreen = true;
  }

  private exitFullScreen(): void {
    const docEl = document.body as any;
    if (!docEl) return;
    const doc = document as any;

    if (doc['exitFullscreen']) doc['exitFullscreen']();
    else if (doc['webkitExitFullscreen']) doc['webkitExitFullscreen']();
    else if (docEl['webkitExitFullScreen']) docEl['webkitExitFullScreen']();
    else if (docEl['webkitCancelFullScreen']) docEl['webkitCancelFullScreen']();
    else if (docEl['mozCancelFullScreen']) docEl['mozCancelFullScreen']();
    else if (docEl['msExitFullscreen']) docEl['msExitFullscreen']();
    this.isFullScreen = false;
  }

  private registerDeviceOrientationEvent() {
    // listen for device orientation events (triggers once on page load)
    fromEvent(window, 'deviceorientation')
      .pipe(
        distinctUntilChanged(),
        startWith(null),
        tap(() => this.handleOrientation()),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  private handleOrientation() {
    const orientation = getDeviceOrientation();
    if (orientation === DeviceOrientation.Portrait) {
      this._dialogConfirmationService.confirm({
        message: 'Portrait mode is currently unsupported. Please rotate your device to landscape mode for the best experience.',
        header: 'Orientation Warning',
        rejectVisible: false,
        acceptLabel: 'Ok',
        accept: () => {
          // logic after dialog is accepted
        },
      });
    } else if (orientation === DeviceOrientation.Landscape) {
      this._dialogConfirmationService.close();
    }
  }
}
