import { Injectable, inject, DestroyRef } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, of } from 'rxjs';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { take } from 'rxjs/operators'; 
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { IActivityRegistry } from '../types/activity-registry.interface';
import { SYLLABLE_SEPARATOR } from '../constants/activity-constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  // global app event stream
  public appEvent$: BehaviorSubject<IAppEvent> = new BehaviorSubject(<IAppEvent>{
    disableUserInteraction: false,
  });

  public resumeSession$: BehaviorSubject<IAppEvent> = new BehaviorSubject<IAppEvent>({
    area: APP_EVENT_AREAS.PAUSED_SESSION_RESUMED, 
    payload: { puzzleOneCompleted: false },
  });

  // make currentActivity available globally
  public currentActivity$: Observable<IActivityRegistry>;

  // todowilson: type this
  public activityDataset$: Observable<any>;
  // todowilson: obviously this seems redundant, but, inexplicably, trying to use currentActivity$ in its place produced strange results
  // todowilson: come back to this...
  public activityRegistry$: Observable<IActivityRegistry>;
  public isRecordingEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly _jumpToFirstPuzzle$: BehaviorSubject<IAppEvent | null> = new BehaviorSubject<IAppEvent | null>(null);
  public jumpToFirstPuzzle$ = this._jumpToFirstPuzzle$.asObservable();

  private _persistedUrlParams: any;
  private readonly _destroyRef = inject(DestroyRef);

  get persistedUrlParams() {
    return this._persistedUrlParams;
  }

  set persistedUrlParams(params: any) {
    this._persistedUrlParams = params;
  }

  constructor() {
    this.appEvent$
      .pipe(
        filter((event) => event.area === APP_EVENT_AREAS.SET_ACTIVITY_REGISTRY),
        map((event) => event.activityRegistry),
        filter(AppHelpers.isDefined),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((levelData) => {
        this.activityRegistry$ = of(levelData);
      });

    this.currentActivity$ = this.appEvent$.pipe(
      filter((event) => event.area === APP_EVENT_AREAS.SET_ACTIVITY_REGISTRY),
      map((event) => event.activityRegistry),
      filter(AppHelpers.isDefined)
    );

    // temp fix for code commented out below
    this.appEvent$
      .pipe(
        filter((event) => event.area === APP_EVENT_AREAS.SET_ACTIVITY_DATASET),
        map((event) => event.dataset),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((dataset) => {
        this.activityDataset$ = of(dataset);
      });


      this.appEvent$
      .pipe(
        filter((event) => event.area === APP_EVENT_AREAS.PUZZLE_COMPLETION_UPDATE),
        map((event) => event.payload),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(({ index, completed }) => {
        this.updatePuzzleCompletion(index, completed);
      });

      this.appEvent$
      .pipe(
        filter((event) => event.area === APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES),
        take(1),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => {
        this.completeAllPuzzles();
      });

    // Listen for the RECORDING_STATE_UPDATE event
    this.appEvent$
      .pipe(
        filter((event) => event.area === APP_EVENT_AREAS.RECORDING_STATE_UPDATE),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((event) => {
        this.isRecordingEnabled$.next(event.payload.isRecordingEnabled);  // Update recording state
      });

      this.appEvent$
      .pipe(
        filter(event => event.area === APP_EVENT_AREAS.JUMP_TO_FIRST_PUZZLE),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(event => {
        this._jumpToFirstPuzzle$.next(event); 
      });
  }

  get level(): number {
    return Number(this.persistedUrlParams['l'][0]);
  }

    // Centralized method to update puzzle completion state
    updatePuzzleCompletion(index: number, completed: boolean): void {
      this.activityDataset$.pipe(
        filter(dataset => !!dataset),
        take(1),
        takeUntilDestroyed(this._destroyRef)
      ).subscribe(dataset => {
        dataset[index].completed = completed;
        this.activityDataset$ = of(dataset);
        this.appEvent$.next({
          area: APP_EVENT_AREAS.SET_ACTIVITY_DATASET,
          dataset: dataset
        });
      });
    }

  // helper method for easy info log
  db(message: string, object: any = null) {
    this.appEvent$.next({
      area: APP_EVENT_AREAS.SHOW_INFO,
      message: message,
      object: object,
    });
  }

  // message bus for audio error
  errorDialog(filename: string, expression: string, callback: any = null) {
    this.appEvent$.next({
      area: APP_EVENT_AREAS.AUDIO_ERROR,
      message: `${filename}${SYLLABLE_SEPARATOR}${expression}`,
      callback: callback,
    });
  }

  private completeAllPuzzles(): void {
    this.activityDataset$.pipe(
      filter(dataset => !!dataset),
      take(1),
      takeUntilDestroyed(this._destroyRef)
    ).subscribe(dataset => {
      dataset.forEach((puzzle: any) => puzzle.completed = true);
      this.activityDataset$ = of(dataset);
      this.appEvent$.next({
        area: APP_EVENT_AREAS.SET_ACTIVITY_DATASET,
        dataset: dataset
      });
    });
  }
}
