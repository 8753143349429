<div id="wlt-activity" class="wlt-activity">
  <div class="game-area">
    <!-- PuzzleType 0 - Syllables -->
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.Words">
      <app-wow-puzzle-type-0
      [currentWorldOfWordsDataset]="currentWorldOfWordsDataset"
      [expressions]="expressions"
      [currentActivity]="currentActivity"
      [skipIntro]="skipIntro"
      (puzzleComplete)="onPuzzleComplete($event)"
      >
      </app-wow-puzzle-type-0>
    </ng-container>

    <!-- PuzzleType 1 - Sentence -->
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.Sentence">
      <app-wow-puzzle-type-1
        [currentWorldOfWordsDataset]="currentWorldOfWordsDataset"
        [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete($event)"
      >
      </app-wow-puzzle-type-1>
    </ng-container>

    <!-- PuzzleType 2 - Read Paragraph -->
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.ReadParagraph">
      <app-wow-puzzle-type-2
        [currentWorldOfWordsDataset]="currentWorldOfWordsDataset"
        [expressions]="expressions"
        (puzzleComplete)="onPuzzleComplete($event)"
      >
      </app-wow-puzzle-type-2>
    </ng-container>

    <!-- PuzzleType 3 - Build Paragraph -->
    <ng-container *ngIf="this.puzzleType === puzzleTypeEnum.BuildParagraph">
      <app-wow-puzzle-type-3
        [currentWorldOfWordsDataset]="currentWorldOfWordsDataset"
        [expressions]="expressions"
        [finalParagraphReview]="finalParagraphReview"
        (puzzleComplete)="onPuzzleComplete($event)"
      >
      </app-wow-puzzle-type-3>
    </ng-container>
  </div>

  <div
    class="progress-bar-area"
    [ngClass]="{
      visible: !activitySolved && displayPB,
      hidden: activitySolved || !displayPB
    }"
  >
  <app-progress-bar
  [totalPuzzles]="totalPuzzles"
  [answeredPuzzles]="answeredPuzzles">
  </app-progress-bar>
  </div>
</div>
