<div id="wlt-activity" class="wlt-activity">
  <div class="game-area">
    <ng-container>
      <app-card-rotator-component *ngIf="wordCards?.length && !carouselProceed" #carousel [parent]="this"
        [cards]="wordCards" (completedCards)="onCarouselCompleted()"
        class="not-so-tricky-carousel"></app-card-rotator-component>

      <app-tw-sentence [parent]="this" *ngIf="!activitySolved && carouselProceed && !showingTransitionPage"
        [trickWordDataset]="currentTrickWordDataset" (completedPuzzle)="onCompletedPuzzle($event)" #twSentence>
      </app-tw-sentence>

      <div class="carousel-play-button" *ngIf="carouselCompleted && !carouselProceed">
      <ng-container>
        <app-start-button (playButtonClicked)="onCarouselProceedClick()"></app-start-button>
      </ng-container>
    </div>
    </ng-container>
  </div>

  <div class="progress-bar-area" [ngClass]="{
    visible: !activitySolved && carouselProceed,
    hidden: !carouselProceed || activitySolved
  }">
    <app-progress-bar [totalPuzzles]="totalPuzzles"  
    [answeredPuzzles]="answeredPuzzles">
    </app-progress-bar>
  </div>
</div>