import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { IMakeAChangeDataset } from '../../types/make-a-change-dataset-interface';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { ATTEMPT_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';


@Component({
  selector: 'app-mac-puzzle-type-0',
  standalone: true,
  imports: [LetterPanelComponent, CommonModule],
  templateUrl: './puzzle-type-0.component.html',
  styleUrl: './puzzle-type-0.component.scss',
})
export class MACPuzzleType0Component extends PuzzleTypeBaseComponent {
  @Input() currentMacDataset: IMakeAChangeDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() newGroup: boolean;
  @Input() currentActivity: any;
  @Input() skipIntro: boolean = false;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() subPartComplete = new EventEmitter();
  @Output() displayPB = new EventEmitter();

  protected letterPanelsActiveWord: ILetterPanel[] = [];
  protected soundIcon = `/assets/play-icon.svg`;
  // protected expOneArray: any[] = [];

  protected prompt: string[] = [];
  protected choices: string[] = [];
  protected answers: string[] = [];
  protected puzzleTypes: number[] = [];
  protected currentLevel: number | undefined = this.appStateService.level;
  protected displayMarking: boolean = false;
  protected markingSrc: string = '';
  protected chosenpanel: ILetterPanel;


  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    // this.expOneArray = [];
    if (!this.currentMacDataset?.expressions) {
      this.letterPanelsActiveWord.forEach((lp) => {
        lp.success = false;
        lp.active = false;
      });
      setTimeout(() => {
        this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
        AnimationHelpers.animate('active-word', ANIMATIONS.BackOutUp);
        setTimeout(() => {
          this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
        }, DELAY.S1)
      }, DELAY.S1)
      return;
    }

    if (this.newGroup) {
      this.startPuzzleMetrics();
      this.answers = [];
      this.prompt = [];
    }

    this.resetTries();
    this.setExpressions(this.expressions);
    this.initActiveWord();
    this.setMaxTries(this.currentMacDataset?.maxTries)
    
    this.validatingPuzzle = false;
    this.displayMarking = false;
    this.markingSrc = '';

    // grab expressions 1.1 through the end to do the beginning chain

    // this.currentMacDataset.expressions.split(SYLLABLE_SEPARATOR).forEach(exp => {
    //   const kvpair = exp.split(COLON_SEPARATOR)
    //   if (parseFloat(kvpair[0]) > 1 && parseFloat(kvpair[0]) < 2) this.expOneArray.push(kvpair[1].trim())

    // })


    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: this.getExpression(1) ?? '',
      callback: () => {
        AnimationHelpers.animate('active-word', ANIMATIONS.Pulse)
      }
    });

    this.letterPanelsActiveWord.forEach(el => {
      if(el.isCorrectAnswer){
        this.answers.push(el.content)
      }
    });

    this.prompt.push(this.getExpression(1) ?? '')
    this.choices = this.letterPanelsActiveWord.map(el => el.content); // do we even want to display choices?
    this.puzzleTypes = this.currentMacDataset ? [this.currentMacDataset.puzzleType] : [];
    this.addPuzzleMeta(this.prompt,this.choices, this.answers, this.puzzleTypes);
    }


  initActiveWord() {
    if (this.currentMacDataset) {
      AppHelpers.buildTargetWordPanels(this.currentMacDataset.word, this.letterPanelsActiveWord);
    }
  }

  onLetterChoiceSelected(selectedPanel: ILetterPanel) {
    if (this.validatingPuzzle) {
      return;
    }

    selectedPanel.active = true;

    this.validatingPuzzle = true;
    selectedPanel.active = true;


    this.soundService.playSound(SOUND_NAMES.Click, () => {
      selectedPanel.active = false;
      if (selectedPanel.isCorrectAnswer) {
        selectedPanel.success = true;
        this.markingSrc = 'assets/checkmark.svg';
        this.displayMarking = true;
        this.chosenpanel = selectedPanel;
        this.addUserAttemptDetails({
          content: selectedPanel.content,
          context: ATTEMPT_CONTEXT.Word,
          result: PUZZLE_STATUS.PASS,
          subPartIdx: this.prompt.length - 1
        });

        AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.Bounce);
        this.soundService.playSound(SOUND_NAMES.Correct, () => {
          // clear mark
          this.soundService.playExpression(this.getExpression(3), () => {
            if (this.currentMacDataset) this.currentMacDataset.completed = true;
            const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
            this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
            this.displayMarking = false;
            this.subPartComplete.emit(false);
            setTimeout(() => {
              this.emitCall.emit();
            }, DELAY.S2)
          });
        });
        return;
      } else {
        selectedPanel.error = true;

        this.addUserAttemptDetails({
          content: selectedPanel.content,
          context: ATTEMPT_CONTEXT.Word,
          result: PUZZLE_STATUS.FAIL,
          subPartIdx: this.prompt.length - 1
        });

        AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);

        if (this.hasTriesExceeded()) {
          this.displayMarking = true;
          this.markingSrc = 'assets/x-mark.svg';
          this.chosenpanel = selectedPanel;
          this.soundService.playSound(SOUND_NAMES.Incorrect);
          this.addMistake();

          if (!this._selfCorrected) {
            this.subpartSelfCorrected(true);
          }

          const correctPanel = this.letterPanelsActiveWord.filter((lp) => lp.isCorrectAnswer)[0];

          this.soundService.playExpression(this.getExpression(5), () => {
            selectedPanel.error = false;
            this.displayMarking = false;
            selectedPanel.active = false;
            this.soundService.playSound(SOUND_NAMES.Correct);
            AnimationHelpers.animate(correctPanel?.id, ANIMATIONS.Tada);
            correctPanel.success = true;

            if (correctPanel) {
              setTimeout(() => {
                correctPanel.success = false;
                this.subPartComplete.emit(false);
                setTimeout(() => {
                  this.emitCall.emit();
                }, DELAY.S2)
              }, DELAY.S2);
            }
          });
        } else {
          this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
            this.soundService.playExpression(this.getExpression(4), () => {
              selectedPanel.error = false;
              selectedPanel.active = false;
              this.validatingPuzzle = false;
            });
          });
        }
      }
    });
  }


  // puzzleEntryAnimateExpression() {
  //   if (this.expOneArray.length > 0) {
  //     this.soundService.playExpressions(this.expOneArray, () => {
  //       if (count !== undefined && count > -1) {
  //         const alarray = this.activeLetters.toArray();
  //         AnimationHelpers.animate(alarray[count].letterPanel.id, ANIMATIONS.Bounce);
  //       }
  //     },
  //       () => {
  //         AnimationHelpers.animate('active-word', ANIMATIONS.Pulse);
  //       }
  //     )
  //   } else {
  //     AnimationHelpers.animate('active-word', ANIMATIONS.Pulse);
  //   }
  // }

}
