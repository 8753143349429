import { Component, DestroyRef, ElementRef, OnInit, QueryList, ViewChildren, inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { SoundService } from '@app/shared/services/sound.service';
import { Observable } from 'rxjs';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { IActivityRegistry } from '@app/shared/types/activity-registry.interface';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: [],
})
export class WelcomeComponent implements OnInit {
  @ViewChildren('object') objectElements: QueryList<ElementRef>;
  protected appEvent$: Observable<IAppEvent>;
  protected currentActivity: IActivityRegistry;
  protected showPage = true;
  protected isReady = false;
  protected objectImage = '';
  protected soundCardImage = '';
  protected animationComplete = false;
  protected numObjects = 10;
  protected objects: number[] = [];

  private readonly _appStateService = inject(AppStateService);
  private readonly _soundService = inject(SoundService);
  private readonly _destroyRef = inject(DestroyRef);

  constructor() { }

  ngOnInit() {
    this.appEvent$ = this._appStateService.appEvent$;

    gsap.registerPlugin(MotionPathPlugin);

    for (let idx = 0; idx < this.numObjects; idx++) {
      this.objects.push(idx);
    }

    this._appStateService.currentActivity$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((activity) => {
      if (activity) {
        this.currentActivity = activity;
        this.objectImage = `/assets/${this.currentActivity.theme.progressBar.marker}`;
        this.soundCardImage = `/assets/${this.currentActivity.theme.soundCardImage}`;
      }
    });

    this._appStateService.resumeSession$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((event) => {
        if (event?.area === APP_EVENT_AREAS.PAUSED_SESSION_RESUMED) {
          this.showPage = false;
          this.animationComplete = true;
          this.isReady = true;
        }
      });

    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.WELCOME && event.welcomeExpression) {
        this.playWelcome(event, event.callback);
      }

      // Handle skipping the intro
      if (event.area === APP_EVENT_AREAS.SKIP_INTRODUCTION || 
        event.area === APP_EVENT_AREAS.JUMP_TO_FIRST_PUZZLE) {
        // Directly set the necessary variables to bypass the intro
        this.showPage = false;
        this.animationComplete = true;
        this.isReady = true;
      }
    });
  }

  private resetState() {
    this.showPage = true;
    this.animationComplete = false;
  }

  private playWelcome(event: IAppEvent, callback?: () => void | undefined) {
    this.resetState();

    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.CENTER_NARRATOR,
    });
    AnimationHelpers.animate('sound-card', ANIMATIONS.BounceInUp);
    this.isReady = true;

    this._soundService.playExpression(event.welcomeExpression, () => {
      this.playAnimation(() => {
        this.animationComplete = true;
        this.showPage = false;
        if (callback) callback();
      });
    });
  }

  protected playAnimation(callback?: () => void | undefined) {
    const tl = gsap.timeline();
    this.objectElements.forEach((objEl, idx) => {
      this.animateObject(tl, idx, callback);
    });
  }

  private animateObject(tl: gsap.core.Timeline, idx: number, callback?: () => void | undefined) {
    const duration = 4;
    const waitDelay = '<5%';
    const self = this;

    tl.to(
      `#object${idx}`,
      {
        duration: duration,
        motionPath: {
          path: '#wave',
          align: '#wave',
          alignOrigin: [0.5, 0.5],
        },

        ease: 'none',

        onStart: () => {
          self._soundService.playSound(SOUND_NAMES.Achievement);
        },
        onComplete: function () {
          if (this['_targets'] && this['_targets'].length > 0) {
            if (this['_targets'][0].classList.contains('last-object')) {
              self._soundService.playSound(SOUND_NAMES.Correct);
              if (callback) callback();
            }
          }
        },
      },
      waitDelay
    );
  }
}
