<div id="pt0">
  <ng-container class="active-word-area">
    <div id="container" class="l{{currentLevel}}">
      <div class="active-word grid grid-nogutter" id="active-word">
        <div *ngFor="let letter of letterPanelsActiveWord;let i = index;" class="active-letter">
          <app-letter-panel-component [letterPanel]="letter" (chosenPanel)="onLetterChoiceSelected($event)"></app-letter-panel-component>
          <img class="sound-icon" src="{{displayMarking && chosenpanel.index === i ? markingSrc : 'assets/soundwave.svg'}}" alt="sound icon" pTooltip="Listen" />
        </div>
      </div>
    </div>
  </ng-container>
</div>
